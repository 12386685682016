import localStore from "../storageAPIs/localStore";
import addLoginAnalytics from "./addLoginAnalytics";

export default (config, state, funcs, loginFunctions, logger) => (isRefresh = state.tokenExpired, simpleReturn) => {
  let callback = false;
  return loginFunctions
    .silentNoPromptLogin(state.cookiesEnabled, isRefresh)
    .then((result) => {
      if (isRefresh) {
        callback = () => config.checkSessionCallback(result.user, result.idToken, result.accessToken);
      } else {
        callback = addLoginAnalytics(
          config,
          state,
          funcs,
          "silent"
        )(result?.user, () =>
          config.loginCallback(null, {
            ...result,
            originUrl: window.location.href,
          })
        );
      }
      return { result: result, callback };
    })
    .catch((e) => {
      if (simpleReturn) return { result: false, callback: () => config.checkSessionCallback(false) };
      switch (e.code) {
        case "login_required":
          localStore.removeIdTokenData();
          logger.error("Autologin failed: ", e.description);
          break;
        case "unauthorized":
          if (e.description === "hv_email_missing") {
            logger.error("User email missing error: ", e, "error");
          }
          break;
        default:
          // Undefined error - clear token state
          logger.error("SSO session check error: ", e, "error");
          state.tokenExpired = false;
      }
      return { result: false, callback: () => config.checkSessionCallback(false) };
    });
};
