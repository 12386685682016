import { css } from "styled-components";

import Apple from "../../components/icons/Apple";
import Facebook from "../../components/icons/Facebook";
import Google from "../../components/icons/Google";
import LinkedIn from "../../components/icons/LinkedIn";
import Letter from "../../components/icons/Letter";
import WindowsLive from "../../components/icons/WindowsLive";

const someStyle = css`
  color: white;
  border: none;
`;

const confs = {
  apple: {
    styles: css`
      ${someStyle}
      background-color: #000;
      color: #fff;
      svg {
        margin-left: 12px;
        padding-bottom: 4px;
        height: 24px;
        max-width: 24px;
      }
    `,
    icon: Apple,
  },
  facebook: {
    styles: css`
      ${someStyle}
      background-color: #4267B2;
      svg {
        margin-left: 12px;
        height: 28px;
        max-width: 28px;
      }
    `,
    icon: Facebook,
  },
  "google-oauth2": {
    styles: css`
      ${someStyle}
      background-color: #4285F4;
      svg {
        height: 42px;
        max-width: 42px;
        margin-left: 2px;
      }
    `,
    icon: Google,
  },
  linkedin: {
    styles: css`
      ${someStyle}
      overflow: hidden;
      background-color: #0073b1;
      svg {
        margin-left: 0;
        height: 100%;
        max-width: 48px;
      }
    `,
    icon: LinkedIn,
  },
  "azure-ad": {
    styles: css`
      ${someStyle}
      background-color: #2f2f2f;
      svg {
        margin-left: 12px;
        margin-right: 6px;
        height: 23px;
        max-width: 23px;
      }
    `,
    icon: WindowsLive,
  },
  windowslive: {
    styles: css`
      ${someStyle}
      background-color: #2f2f2f;
      svg {
        margin-left: 12px;
        margin-right: 6px;
        height: 23px;
        max-width: 23px;
      }
    `,
    icon: WindowsLive,
  },
  passwordless: {
    styles: css`
      background-color: #fff;
      border: 1px solid #000;
      line-height: 20px;
      svg {
        width: 21px;
        margin: 0 6px 0 14px;
      }
      &:hover {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), inset 0 0 0 0.7px rgba(0, 0, 0, 1);
      }
    `,
    icon: Letter,
    pwdless: true,
  },
};
export default (provider) => confs[provider] || null;
