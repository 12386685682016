import auth0 from "auth0-js";
import fetch from "unfetch";
import Authenticator from "../Authenticator";

export default (config) => {
  const webAuth = new auth0.WebAuth({
    clientID: config.clientID,
    domain: config.domain,
    scope: `${config.scope.replace("silent", "register")}`,
    responseType: config?.responseType || "token id_token",
    connection: config.realm,
    redirectUri: config.redirectUrl,
    leeway: 1,
  });

  const checkSession = () =>
    new Promise((resolve, reject) => {
      try {
        webAuth.checkSession(
          {
            audience: config.audience,
            scope: config.scope,
          },
          (err, succ) => (err ? reject(err) : resolve(succ))
        );
      } catch (error) {
        reject(error);
      }
    });

  const login = async (username, password, scope) => {
    const response = await fetch(`https://${config.domain}/co/authenticate`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: config.clientID,
        credential_type: "http://auth0.com/oauth/grant-type/password-realm",
        password,
        username,
        realm: config.realm,
      }),
    });
    if (response.status === 200) return checkSession(scope);
    const errorData = await response.json();
    throw errorData;
  };

  const logout = async (rTo) => webAuth.logout({ returnTo: rTo || window.location.origin });
  //! TODO remove modal for 3.0
  const redirectToUniversal = ({ email, lng, modal, stage, prompt, source, state, type, connection, redirectUri }) =>
    webAuth.authorize({
      connection,
      clientID: config.clientID,
      realm: config.realm,
      redirectUri: redirectUri || config.redirectUrl,
      scope: `${config.scope} gaevent:silent`,
      audience: config.audience,
      email,
      lng,
      m: modal || stage,
      u: `${window.location.origin}${window.location.pathname}`,
      prompt,
      s: source,
      cmp: JSON.stringify({ ...state.CMPData, ...state.B2BCMPData }),
      t: type || state.type,
      disableGa: config.disableGa,
    });

  const silentLogin = async () => checkSession(`${config.scope}`);

  return Object.assign(
    { login, checkSession, logout, redirectToUniversal, silentLogin, webAuth },
    Authenticator(config, webAuth, login)
  );
};
