import defaultConfig from "../defaultConfig";
import Env from "../env";
import EmailFunctions from "../functions/EmailFunctions";
import { AnalyticsBuilder } from "../ga";
import Logger from "../logger/log";
import sessionStore from "../storageAPIs/sessionStore";
import { getCMPDataFromUrl, getSourceFromUrl } from "../utils";
import addCMPAvailableListener, {
  addB2BCMPListener,
  addUserOptedInListener,
  defaultCMPData,
} from "./checkers/runCMPCheck";
import storageIsAvailable from "./checkers/storageIsAvailable";
import EmbeddedAuthenticator from "./EmbeddedAuthenticator";
import EmbeddedCoreUserInterface from "./EmbeddedCoreUserInterface";
import PubSub from "./user/PubSub";
import UserStore from "./user/UserStore";
import {
  setCMPData,
  setB2BCMPData,
  setConsentsFromConfig,
  allowWaitGAConfig,
  setAnalyticsWait,
} from "./EmbeddedCoreStateUtils";

let instance = null;
let lng = "fi";
const setLang = (lang) => {
  lng = lang || "fi";
};

const parseCMPData = (config) => {
  const cmp = config.cmp;
  const URLConsentData = getCMPDataFromUrl();

  if (typeof cmp === "boolean")
    return { standard: cmp, facebook: cmp, google: cmp, ga: cmp, linkedin: cmp, apple: cmp };
  if (cmp) return cmp;
  if (URLConsentData) return JSON.parse(URLConsentData);
  return defaultCMPData;
};

const EmbeddedCore = (baseConfig) => {
  const config = { ...defaultConfig, ...baseConfig };

  const URLSource = getSourceFromUrl();

  const state = {
    env: Env(config),
    isUniversal: false,
    tokenExpired: false,
    cookiesEnabled: false,
    storageIsAvailable: storageIsAvailable(),
    CMPData: parseCMPData(config),
    B2BCMPData: false,
    source: URLSource || config.s || config.source,
    analyticsWait: config.analyticsWait && allowWaitGAConfig(config.consent),
  };

  config.storedSource = sessionStore.getSource();

  sessionStore.removeSource();

  const logger = Logger(config);
  const funcs = {};
  funcs.logger = logger;

  funcs.authenticator = EmbeddedAuthenticator(config);
  funcs.events = PubSub();
  const userStore = UserStore(funcs.events);
  funcs.emailFunctions = EmailFunctions(config, state, userStore);
  funcs.analytics = AnalyticsBuilder(config, state, logger);

  (() => {
    if (config.consent) {
      setConsentsFromConfig(state, config);
      setAnalyticsWait(state, config);
      if (state.CMPData.ga) funcs.analytics = funcs.analytics.build();
    } else {
      const B2BCMPCallback = (B2BCMPData) => {
        setB2BCMPData(state, B2BCMPData);
      };
      const CMPCallback = (CMPData) => {
        setCMPData(state, CMPData);
        setAnalyticsWait(state, config);
        const gaConsent = CMPData.ga;
        const gaInitialized = !funcs.analytics.build;

        if (gaConsent && !gaInitialized) {
          funcs.analytics = funcs.analytics.build();
        } else if (gaInitialized) funcs.analytics.setDisabled(!gaConsent);
      };

      if (typeof window !== "undefined") {
        addB2BCMPListener(B2BCMPCallback);
        addUserOptedInListener(CMPCallback);
        addCMPAvailableListener(CMPCallback);
      }
    }
  })();

  return Object.assign(
    {
      analytics: funcs.analytics,
      env: state.env,
    },
    EmbeddedCoreUserInterface(config, state, funcs, userStore, lng)
  );
};

export default (config) => {
  setLang(config.lng);
  if (instance) return instance;
  instance = EmbeddedCore(config);
  Object.seal(instance);
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    instance.runInitCheck();
    window.ALMA.tunnus = Object.assign(window.ALMA.tunnus, instance);
  }

  return instance;
};
