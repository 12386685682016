import sessionStore from "../storageAPIs/sessionStore";
import { updateQueryStringParameter } from "../utils";
import { checkUserHash, getParseParams, initSessionFromHashResult } from "./InitFromURLHashFunctions";

export const getLoginHandler = (config, loginFunctions, funcs, state) => (url, windowFeatures, source) => () => {
  if (!url) return;
  const features = windowFeatures || `top=${window.screen.height * 0.5},left=${window.screen.width * 0.5}`;
  const evtSource = source || state.source;

  window.ALMA.closeLogin = (hash) => {
    loginWindow?.close();
    getParseParams(config)(hash)
      .then(checkUserHash(funcs.logger, funcs.events, funcs.authenticator.webAuth))
      .then(
        initSessionFromHashResult(
          { ...config, storedSource: evtSource },
          state,
          loginFunctions,
          funcs,
          sessionStore.getOriginUrl()
        )
      )
      .then(({ cb }) => cb());
  };

  let windowUrl = url;
  windowUrl = updateQueryStringParameter(windowUrl, "cmp", JSON.stringify(state.CMPData));

  if (evtSource) {
    windowUrl = updateQueryStringParameter(windowUrl, "source", evtSource);
    sessionStore.setSource(evtSource);
  }
  const loginWindow = window.open(windowUrl, "_blank", features);
};

export const getLogoutHandler = (reset, funcs, logout) => (url, callback, windowFeatures) => () => {
  if (url) {
    window.ALMA.closeLogout = () => {
      logoutWindow?.close();
      reset();
      funcs.analytics.sendLogout();
      if (callback) callback();
    };

    const features =
      windowFeatures ||
      `menubar=0,status=0,titlebar=no,width=100,height=100,top=${window.screen.height * 0.5},left=${
        window.screen.width * 0.5
      }`;

    const logoutWindow = window.open(url, "_blank", features);
  } else logout();
};
