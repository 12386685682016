import localStore from "../storageAPIs/localStore";
import sessionStore from "../storageAPIs/sessionStore";
import ThirdPartyCookieChecker from "./checkers/ThirdPartyCookieChecker";
import checkSSOSession from "./checkSSOSession";
import checkUserToken from "./checkUserToken";
import clearUrl from "./clearUrl";
import { checkUserHash, getParseParams, initSessionFromHashResult } from "./InitFromURLHashFunctions";
import { resolveSessionType, SESSION, URL } from "./InitSessionType";

const InitCheck = (config, state, funcs, loginFunctions) => {
  const thirdPartyCookieCheck = async () => ThirdPartyCookieChecker(config, funcs).runCookieCheck();

  const shouldRefresh = (isNewLogin, email_verified, tokenExpired) =>
    !isNewLogin && (email_verified === false || tokenExpired);

  const resolveSession = async ([cookieResult, urlResult, storageResult]) => {
    state.cookiesEnabled = cookieResult;
    const someProvider = sessionStore.getSomeLoginFlow();
    const originUrl = sessionStore.getOriginUrl();

    let callback = null;
    let result = null;
    let isNewLogin = false;

    const sessionResultType = resolveSessionType(urlResult, storageResult);
    if (sessionResultType === URL) {
      const { cb, hashInitResult, newLogin } = initSessionFromHashResult(
        config,
        state,
        loginFunctions,
        funcs,
        originUrl
      )(urlResult);
      callback = cb;
      result = hashInitResult;
      isNewLogin = newLogin;
    } else if (sessionResultType === SESSION) {
      result = storageResult;
      const idToken = localStore.getIdTokenData().idToken;
      const user = loginFunctions.setUserSession(storageResult, false);
      callback = () => config.checkSessionCallback(user, idToken);
    } else callback = () => config.checkSessionCallback(false);

    if (!config.disableUrlClearing) clearUrl();
    sessionStore.removeSomeLoginFlow();

    const isRefresh = shouldRefresh(isNewLogin, result?.email_verified, state.tokenExpired);
    if (!result || isRefresh) {
      if (someProvider) funcs.analytics.sendSomeFailure(someProvider);
      if (cookieResult && isRefresh) {
        const ssoCheck = await checkSSOSession(config, state, funcs, loginFunctions, funcs.logger)(true, false);
        if (ssoCheck?.result) {
          callback = ssoCheck.callback;
        }
      }
      state.tokenExpired = false;
    }
    return callback;
  };

  const getTokenFromURL = () =>
    getParseParams(config)().then(checkUserHash(funcs.logger, funcs.events, funcs.authenticator.webAuth));

  return {
    thirdPartyCookieCheck,
    initUserURL: checkUserHash,
    shouldRefresh,
    resolveSession,
    resolveSessionType,
    run: () =>
      Promise.all([
        thirdPartyCookieCheck(),
        getTokenFromURL(),
        checkUserToken(state, funcs)(localStore.getIdTokenData()),
      ]).then(resolveSession),
  };
};

export default InitCheck;
