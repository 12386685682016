import sessionStore from "../storageAPIs/sessionStore";
import storeToken from "./storeToken";

export default (authenticator, userStore) => {
  const setUserSession = (idToken, isLoginEvent = true) => {
    userStore.setUser(idToken, isLoginEvent);
    return userStore.getUser();
  };

  const handleSilentResult = (expired, pwdless) => async (result) => {
    const { idToken, idTokenPayload } = result;
    const user = setUserSession(idTokenPayload, pwdless || !expired);
    storeToken(idToken, result);
    const { accessToken } = result;
    return { accessToken, user, idToken: result.idToken };
  };

  const refreshNoRedirect = async (expired, pwdless) =>
    authenticator.silentLogin().then(handleSilentResult(expired, pwdless));

  const refreshWithRedirect = () => {
    sessionStore.setOriginUrl(window.location.href);
    return authenticator.webAuth.authorize({ prompt: "none" });
  };

  return {
    refreshNoRedirect,

    handleSilentResult,

    setUserSession,

    login: async (username, password) => {
      const loginResult = await authenticator.loginUser(username, password);
      if (loginResult.code || loginResult.error) throw loginResult;
      const { idToken, idTokenPayload } = loginResult;
      const user = setUserSession(idTokenPayload);
      storeToken(idToken, loginResult);

      return { accessToken: loginResult.accessToken, user, idToken };
    },

    silentNoPromptLogin: async (cookiesEnabled, expired, pwdless = false) => {
      if (cookiesEnabled) return refreshNoRedirect(expired, pwdless);
      if (expired) return refreshWithRedirect();
      return null;
    },

    logout: async (rTo) => authenticator.logout(rTo),
  };
};
