import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import useInputFocus from "../../hooks/useInputFocus";
import { FieldContainer, InputContainer, InputField, InputLabel } from "./InputComponents";

const Input = forwardRef((props, ref) => {
  const { disabled, id, isError, isValid, label, ...rest } = props;
  const [focused, onInputBlur, onInputFocus] = useInputFocus();
  const labelId = `${id}-label`;

  const handleFocus = (e) => {
    onInputFocus(e);
    if (props.onFocus) props.onFocus(e);
  };
  const handleBlur = (e) => {
    onInputBlur(e);
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <InputContainer>
      {label && (
        <InputLabel id={labelId} isValid={isValid} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <FieldContainer>
        <InputField
          {...rest}
          focused={focused}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-labelledby={labelId}
          disabled={disabled}
          id={id}
          isValid={isValid}
          isError={isError}
          ref={ref}
        />
      </FieldContainer>
    </InputContainer>
  );
});

Input.defaultProps = {
  disabled: false,
  label: null,
  isValid: true,
  isError: false,
};

Input.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  isValid: PropTypes.bool,
  isError: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default Input;
