import {
  getConsentValidationError,
  getEmailIsValid,
  getEmailValidationError,
  getFirstNameValidationError,
  getLastNameValidationError,
  getPasswordValidationError,
  getPasswordValidity,
} from "./functions/Validators";
import sessionStore from "./storageAPIs/sessionStore";

const TunnusCore = (config, state, funcs) => {
  const handleVerificationResult = (result) => {
    funcs.logger.log(JSON.stringify(result));
    return result;
  };

  const randomString = (length) => {
    const charset = "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._";
    let result = "";

    for (let i = length; i > 0; ) {
      const bytes = new Uint8Array(16);
      const random = window.crypto.getRandomValues(bytes);
      result += random.reduce((acc, c) => {
        if (i <= 0) return acc;
        if (c < charset.length) {
          i -= 1;
          return acc + charset[c];
        }
        return acc;
      });
    }
    return result;
  };

  const generateAndStoreNonceAndState = () => {
    const nonce = randomString(16);
    const authState = randomString(16);
    window.localStorage.setItem("nonce", nonce);
    window.localStorage.setItem("state", authState);
    return { nonce, authState };
  };

  const appendValidEmail = (email) => (getEmailIsValid(email) ? `&email=${email}` : "");

  if (typeof window !== "undefined" && typeof document !== "undefined") {
    window.ALMA = window.ALMA || {};
    window.ALMA.tunnus = {
      ...window.ALMA.tunnus,
      version: state.env.componentVersion || "",
      buildTime: state.env.buildTime || "",
    };
  }

  return {
    setSource: async (source, type) => {
      state.source = source;
      if (type) state.type = type;
    },

    getSource: () => state.source,

    someLogin: async (provider) => {
      funcs.logger.log(`someLogin(${provider})`);
      sessionStore.setSomeLoginFlow(provider);
      funcs.authenticator.someLogin(provider);
    },

    urlContainsHash: () =>
      typeof window !== "undefined"
        ? (window.location.href.indexOf(config.redirectUrl) !== -1 &&
            window.location.hash !== undefined &&
            window.location.hash.indexOf("access_token") !== -1 &&
            window.location.hash.indexOf("id_token") !== -1) ||
          window.location.hash.indexOf("error") !== -1
        : false,

    sendVerificationEmail: async (user) =>
      funcs.emailFunctions
        .sendVerificationEmail(user.id)
        .then(handleVerificationResult)
        .catch((error) => error),

    sendPwdless: async (email) => {
      const { nonce, authState } = generateAndStoreNonceAndState();
      const uri = state.isUniversal ? document.referrer : window.location.origin;
      const redirectUri = `${uri.slice(-1) === "/" ? uri.slice(0, uri.length - 1) : uri}?f=np&auth0=1`;
      return fetch(`${state.env.selfServiceUrl}/v1/self-service/passwordless`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            send: "link",
            clientId: config.clientID,
            email,
            authParams: {
              redirect_uri: redirectUri,
              response_type: funcs.authenticator.state.responseType,
              nonce,
              state: authState,
            },
          },
        }),
      })
        .then((result) => result)
        .catch((error) => error);
    },

    sendPwdReset: async (email) => {
      funcs.analytics.sendPasswdReset();
      return fetch(encodeURI(`${state.env.selfServiceUrl}/v1/self-service/request_password_change`), {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: { email, clientId: config.clientID } }),
      })
        .then((r) => {
          funcs.analytics.sendPasswdReset(true);
          return r;
        })
        .catch((err) => {
          funcs.analytics.sendPasswdReset(false);
          return err;
        });
    },
    getResetEmailUrl: funcs.emailFunctions.getResetEmailUrl,
    getVerifyEmailUrl: funcs.emailFunctions.getVerifyEmailUrl,
    getRegistrationUrl: funcs.emailFunctions.getRegistrationUrl,
    getPasswordResetUrl: (url, email) => encodeURI(`${url}?clientId=${config.clientID}${appendValidEmail(email)}`),
    getPasswordValidity: getPasswordValidity,
    getEmailValidity: getEmailIsValid,
    validationErrorGetters: {
      firstName: getFirstNameValidationError,
      lastName: getLastNameValidationError,
      email: getEmailValidationError,
      password: getPasswordValidationError,
      consent: getConsentValidationError,
    },
  };
};

export default TunnusCore;
