import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { NoGuaranteeAlert } from "../components/AlertMessage";
import CloseButton from "../components/CloseButton";
import { Backdrop, ContentContainer, MainContainer } from "../components/Containers";
import P from "../components/P";
import { useModalStageContext } from "../providers/modal/ModalStageContext";
import { useUIContext } from "../providers/UIContextProvider";
import Passwordless from "./EmailActions/Passwordless";
import PasswordlessSent from "./EmailActions/PasswordlessSent";
import ResetPassword from "./EmailActions/ResetPassword";
import ResetSent from "./EmailActions/ResetSent";
import Footer from "./Footer";
import Loading from "./Loading";
import Login from "./Login/Login";
import RegisterForm from "./register/RegisterForm";
import SignupInfo from "./register/SignupInfo";

const modalStages = Object.freeze({
  LOGIN: Login,
  REGISTER: RegisterForm,
  INFO: SignupInfo,
  PWDLESS: Passwordless,
  NOPWDSENT: PasswordlessSent,
  RESET: ResetPassword,
  RESETSENT: ResetSent,
});

const getModal = (modalStage) => modalStages[modalStage] || modalStages.LOGIN;

const ModalFrame = () => {
  const { windowSize, t, assetsLoading, core, assets } = useUIContext();
  const { closeModalOnClick, modalStage, isUniversal } = useModalStageContext();
  const modalTop = useRef(null);
  const closeOnKeyUp = (e) => {
    const ESC_KEYCODE = 27;
    if (e.keyCode === ESC_KEYCODE) closeModalOnClick();
  };

  useEffect(() => {
    if (typeof document === "undefined") return () => {};
    document.addEventListener("keyup", closeOnKeyUp);
    return () => {
      document.removeEventListener("keyup", closeOnKeyUp);
      core.setSource(null); // Unset modal opener id
    };
  }, []);

  useEffect(() => modalTop.current.scrollIntoView({ block: "start" }), [modalStage]);

  const Modal = getModal(modalStage);

  const backgroundImage =
    (isUniversal && assets?.common?.backgroundImgUrl) ||
    (!isUniversal && assets.settings?.showEmbeddedBackground && assets?.common?.backgroundImgUrl);

  const logoContainerWidth = assets?.styles?.logoContainerWidth ? assets.styles.logoContainerWidth : false;

  // Test image
  // const backgroundImage =
  //   "https://images.unsplash.com/photo-1534821609481-e6e7d4febfc9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2691&q=80";

  return (
    <Backdrop
      className="AlmaTunnusComponent modalBaseView"
      lang="fi"
      role="dialog"
      aria-modal="true"
      aria-label={t("headerLogin")}
      backgroundImage={backgroundImage}
    >
      <ScrollToTop ref={modalTop} />
      <MainContainer role="presentation" onClick={(e) => e.stopPropagation()}>
        {assetsLoading ? (
          <Loading />
        ) : (
          <>
            <ContentContainer>
              <HeaderContainer>
                <CloseButton title={t("common.closeLoginTitle")} windowSize={windowSize} />
                <LogoContainer logoContainerWidth={logoContainerWidth} logoUrl={t("common.logoUrl")}>
                  <HeaderLogo src={t("common.logoUrl")} alt={t("common.serviceName")} />
                </LogoContainer>
              </HeaderContainer>
              {!isUniversal && !core.storageIsAvailable && (
                <NoGuaranteeAlert>
                  <P role="alert" dangerouslySetInnerHTML={{ __html: t("errors.noGuarantee") }} />
                </NoGuaranteeAlert>
              )}
              <Modal />
            </ContentContainer>
            <Footer />
          </>
        )}
      </MainContainer>
    </Backdrop>
  );
};

export default ModalFrame;

// STYLED COMPONENT
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

const ScrollToTop = styled.div`
  position: absolute;
  top: 0;
  opacity: 0;
`;

const HeaderContainer = styled.header`
  box-sizing: border-box;
  position: relative;
  text-align: center;
  height: 35px;
  width: 100%;
  margin: 20px 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled.div`
  box-sizing: border-box;
  min-width: 70px;
  max-width: 210px;
  /* fallback to 145px */
  width: 145px;
  width: ${({ logoContainerWidth }) => (logoContainerWidth ? logoContainerWidth : "145px")};
  height: 100%;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Super ugly hack to fix logo rendering in IE11. Target IE 10 or later, 
  hide img, set logo as background image since nothing else seems to work. */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    > img {
      display: none;
    }
    background-image: url(${({ logoUrl }) => (logoUrl ? logoUrl : "")});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const HeaderLogo = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  flex-grow: 1;
`;
